import Sidebar from "./sideBar";
import { useAppDispatch } from "../../hooks";
import { getOrderByUserIdAsync, getAdressByUserIdAsync, deleteAddressAsync, updateAddressAsync } from "../../services/reducers/reciepeReducer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import pencil from "../../assets/images/Pencil.png";
import trash from "../../assets/images/Trash.png";
import add from "../../assets/images/addIcon.png";
import home from "../../assets/images/home.png";
import AddressModal from "../CheckOut/AddressModal";

const allergiesOptions = [
  ['Peanuts', 'Treenuts', 'Milk Product', 'Fish', 'Eggs'],
  ['Coconut', 'Meat', 'Dairy', 'Non-veg', 'Shellfish'],
  ['Mustard']
];
  

const ProfilePage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId");
  const userEmail = localStorage.getItem("userEmail") || "";
  const userPhone = localStorage.getItem("userPhone") || "";
  const userName = localStorage.getItem("userName") || "AnyFeast User";
  const [cartAddress, setCartAddress] = useState<any>([]);

  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  const [orderDetail, setOrderDetail] = useState<any>([]);
  const [EditAddressData, setEditAddressData] = useState(null);
  const navigate = useNavigate();
  const [selectedSidebarItem, setSelectedSidebarItem] = useState<string>("Orders");
  const [selectedAllergies, setSelectedAllergies] = useState<string[]>([]);
  const [addressId, setAddressId] = useState(null);
    const storedCode = localStorage.getItem('countryCode');

    // type Address = {
    //   address_id: string;
    //   address_type: string;
    //   flat_no?: string;
    //   street_address?: string;
    //   city: string;
    //   state_province: string;
    //   postal_code: string;
    //   country: string;
    // };
  
  useEffect(() => {
    if (userId) {
      dispatch(getOrderByUserIdAsync(userId)).then((res) => {
        if (res.payload) {
          setOrderDetail(res.payload?.data);
        }
      });
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      dispatch(getAdressByUserIdAsync(userId)).then((res) => {
        if (res.payload) {
          setCartAddress(res.payload?.data);
        }
      });
    }
  }, [userId]);
  const toggleAllergy = (allergy: string) => {
    setSelectedAllergies(prev =>
      prev.includes(allergy)
        ? prev.filter(item => item !== allergy)
        : [...prev, allergy]
    );
  };

  const filteredOrdersPast = orderDetail.filter(
    (order: any) => order.status === "complete" || order.status === "cancelled"
  );
  const filteredOrders = orderDetail.filter(
    (order: any) => order.status === "processing"
  );

  const getAddress = () => {
    if (userId) {
      dispatch(getAdressByUserIdAsync(userId)).then((res) => {
        if (res.payload) {
          setCartAddress(res.payload?.data);
        }
      });
    }
  }


  const handleSidebarClick = (itemName: string) => {
    if (itemName === "About Us") {
      navigate("/OurStory"); // Navigate to the OurStoryTwo page
    } else {
      setSelectedSidebarItem(itemName);
    }
  };
  

  const handleView = (id: string) => {
    navigate(`/order/${id}`);
  };

  // const handleDelete = (id: any) => {
  //   if (userId) {
  //     dispatch(deleteAddressAsync(id));
  //     getAddress();
  //   }
  // };

  const handleDelete = (id: any) => {
    if (userId) {
      dispatch(deleteAddressAsync(id)).then((res) => {
        if (res.payload?.success) { 
          dispatch(getAdressByUserIdAsync(userId)).then((res) => {
            if (res.payload) {
              setCartAddress(res.payload.data);
            }
          });
        }
      });
    }
  };
  
  console.log("CArt", cartAddress)
  
  const handleEdit = (id: any) => {
    setAddressModalOpen(true);
    setAddressId(id);
    console.log('edit id:', id);
  
    if (userId) {
      const selectedAddress = cartAddress.find(
        (address: any) => address.address_id === id
      );
      if (!selectedAddress) return;
  
      const reqBody = {
        street_address: selectedAddress.street_address,
        city: selectedAddress.city,
        state_province: selectedAddress.state_province,
        postal_code: selectedAddress.postal_code,
        country: selectedAddress.country,
      };
  
      dispatch(updateAddressAsync({ id, reqBody })).then((res) => {
        if (res.payload?.success) {
          setEditAddressData(res.payload.data);
          // setAddressModalOpen(false);
        }
      });
    }
  };
  const handleAddClick = () => {
    openAddressModal(); 
  };

  // const handleEdit = (id: string) => {
  //   console.log("Editing Address ID:", id);
  //   setAddressId(id);
  //   setAddressModalOpen(true);
  // };
  // const selectedAddress = cartAddress.find(
  //   (address: any) => address.address_id === addressId
  // );
  const openAddressModal = (addressId = null, addressData = null) => {
    setAddressId(addressId);
    setEditAddressData(addressData); 
    setAddressModalOpen(true);
  };
  
  const renderContent = () => {
    switch (selectedSidebarItem) {
      case "Addresses":
        return (
          <div className="flex flex-col gap-8 w-full ">
            {/* Add New Address Section */}
            <div className="flex justify-between items-center w-full bg-white rounded-lg p-5 sm:px-8">
              <div className="px-2 sm:px-3">Add New Address</div>
              <button onClick={handleAddClick}>
                <img src={add} className="cursor-pointer" alt="Add Address" />
              </button>
            </div>
      
            {/* Address Cards */}
            {cartAddress.map((address: any, index: number) => (
              <div
                key={index}
                className="flex flex-col w-full bg-white rounded-lg p-5 sm:px-8"
              >
                <div className="font-semibold flex items-center gap-2">
                  <img src={home} height={10} width={15} alt="Home Icon" />
                  {address.address_type}
                </div>
                <div className="p-5">
                  <label className="text-[#202020]">
                    {address.flat_no ? `${address.flat_no}, ` : ''}
                    {address.street_address ? `${address.street_address}, ` : ''}
                    {address.city}, {address.state_province}, {address.postal_code},{' '}
                    {address.country}
                  </label>
                </div>
                <div className="px-3 flex gap-4 sm:gap-10">
                  <div
                    className="flex gap-2 cursor-pointer"
                    onClick={() => {
                      handleEdit(address.address_id);
                     
                    }}
                  >
                    <img src={pencil} alt="Edit" />
                    <span className="text-green">Edit</span>
                  </div>
                  <div
                    className="flex gap-2 cursor-pointer"
                    onClick={() => handleDelete(address.address_id)}
                  >
                    <img src={trash} alt="Delete"/>
                    <span>Delete</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      
  
      case "Orders":
        return (
          <div>
            {filteredOrders.map((order: any) => (
              <div
                key={order.id}
                className="bg-white p-4 rounded-lg shadow mb-5 cursor-pointer"
                onClick={() => handleView(order.id)}
              >
                <h3 className="text-lg font-semibold mb-2">
                  Arriving By Today
                </h3>
                <p>Order Number: #{order.id}</p>
                <p>Total: {order.currency === "INR" ? "₹" : "£"}{order.order_total}</p>
  
                <div className="flex mt-3">
                  {order.items.map((item: any) => (
                    <img
                      key={item.id}
                      className="w-12 h-12 rounded-md mr-2"
                      src={item.info.image || "https://via.placeholder.com/50"}
                      alt="product"
                    />
                  ))}
                </div>
              </div>
            ))}
  
            {filteredOrdersPast?.length > 0 && (
              <h2 className="text-xl font-bold mb-5">Past Orders</h2>
            )}
            {filteredOrdersPast.map((order: any) => (
              <div
                key={order.id}
                className="bg-white p-4 rounded-lg shadow mb-5"
              >
                <h3 className="text-lg font-semibold text-green-500">
                  {order.status === "complete" ? "Delivered" : ""}
                </h3>
                <p>Arrived on Apr 15 2024</p>
                <p>Order Number: #{order.id}</p>
                <p>Total: {storedCode === "IN" ?"₹" : "£"}{order.order_total}</p>
                <div className="flex mt-3">
                  {order.items.map((item: any) => (
                    <img
                      key={item.id}
                      className="w-12 h-12 rounded-md mr-2"
                      src={item.info.image || "https://via.placeholder.com/50"}
                      alt="product"
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
  
      case "Profile":
        return (
          <div className="flex flex-col gap-10 w-full sm:px-3 md:px-2 sm: ">
            <div className="relative w-full">
              <input
                type="text"
                id="phone"
                disabled
                placeholder={userPhone}
                className="peer block w-full appearance-none border-2 border-[#3131311A] bg-transparent px-4 py-4 rounded-xl text-base text-gray-900 focus:outline-none focus:border-[#767676] placeholder:text-[#181D27]"
              />
              <label
                className="px-2 absolute left-3 -top-3 text-sm text-[#767676] bg-gray-100
                           peer-placeholder-shown:top-none peer-placeholder-shown:left-3 peer-placeholder-shown:text-sm peer-placeholder-shown:text-[#767676]  
                           peer-focus:-top-3 peer-focus:left-3 peer-focus:text-sm 
                           peer:not(:placeholder-shown):-top-3 peer:not(:placeholder-shown):text-sm"
              >
                Phone
              </label>
            </div>
            <div className="relative w-full">
              <input
                type="text"
                id="name"
                disabled
                placeholder={userName}
                className="peer block w-full appearance-none border-2 border-[#3131311A] bg-transparent px-4 py-4 rounded-xl text-base text-gray-900 focus:outline-none focus:border-[#767676] placeholder:text-[#181D27]"
              />
              <label
                className="px-2 absolute left-3 -top-3 text-sm text-[#767676] bg-gray-100
                           peer-placeholder-shown:top-none peer-placeholder-shown:left-3 peer-placeholder-shown:text-sm peer-placeholder-shown:text-[#767676] 
                           peer-focus:-top-3 peer-focus:left-3 peer-focus:text-sm 
                           peer:not(:placeholder-shown):-top-3 peer:not(:placeholder-shown):text-sm"
              >
                Name
              </label>
            </div>
            <div className="relative w-full">
              <input
                type="text"
                id="email"
                disabled
                placeholder={userEmail}
                className="peer block w-full appearance-none border-2 border-[#3131311A] bg-transparent px-4 py-4 rounded-xl text-base text-gray-900 focus:outline-none focus:border-[#767676] placeholder:text-[#181D27]"
              />
              <label
                className="px-2 absolute left-3 -top-3 text-sm text-[#767676] bg-gray-100
                           peer-placeholder-shown:top-none peer-placeholder-shown:left-3 peer-placeholder-shown:text-sm peer-placeholder-shown:text-[#767676] 
                           peer-focus:-top-3 peer-focus:left-3 peer-focus:text-sm 
                           peer:not(:placeholder-shown):-top-3 peer:not(:placeholder-shown):text-sm"
              >
                Email ID
              </label>
            </div>
            <div className="border-2 border-[#3131311A] rounded-xl p-4 flex flex-row justify-between mt-10 items-center">
              <div>Delete Account</div>
              <div>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="#767676"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        );
      
        case "Allergies & Dislikes":
          return (
            <div className="flex flex-col gap-8 bg-white rounded-lg px-4 sm:px-6 py-6">
              {/* Allergies Options */}
              <div className="grid gap-4 grid-cols-2 sm:grid-cols-3 sm:flex sm:flex-wrap sm:gap-4 sm:justify-start">
                {allergiesOptions.flat().map((allergy) => (
                  <button
                    key={allergy}
                    onClick={() => toggleAllergy(allergy)}
                    className={`w-full h-12 sm:w-auto px-6 py-2 rounded-md border-2 text-sm font-medium transition-colors
                      ${
                        selectedAllergies.includes(allergy)
                          ? 'bg-red-100 text-green border-green'
                          : 'bg-white text-black border-[#EEEEEE] hover:border-[rgb(255,243,243)]'
                      }`}
                  >
                    {allergy}
                  </button>
                ))}
              </div>
        
              {/* Save Button */}
              <div className="flex justify-center sm:justify-end">
                <button className="px-[53px] py-2 bg-green text-white rounded-lg font-medium hover:bg-green-hover transition-colors">
                  Save
                </button>
              </div>
            </div>
          );
        
        default:
          return null;
        }
        };
        
  
  
return (
  <div className="min-h-screen bg-white p-16">
    <div className="flex relative">
      {/* Sidebar container for larger screens */}
      <div className="hidden md:block">
        {/* User Info for Larger Screens */}
        <div className="hidden md:flex bg-[#ffdcdc] items-center p-5 rounded-tr-lg rounded-tl-lg bg-opacity-50">
          <span className="inline-block size-[42px] bg-gray-100 rounded-full overflow-hidden mr-4">
            <svg
              className="size-full text-gray-300"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.62854"
                y="0.359985"
                width="15"
                height="15"
                rx="7.5"
                fill="white"
              ></rect>
              <path
                d="M8.12421 7.20374C9.21151 7.20374 10.093 6.32229 10.093 5.23499C10.093 4.14767 9.21151 3.26624 8.12421 3.26624C7.0369 3.26624 6.15546 4.14767 6.15546 5.23499C6.15546 6.32229 7.0369 7.20374 8.12421 7.20374Z"
                fill="currentColor"
              ></path>
              <path
                d="M11.818 10.5975C10.2992 12.6412 7.42106 13.0631 5.37731 11.5537C5.01171 11.2818 4.69296 10.9631 4.42107 10.5975C4.28982 10.4006 4.27107 10.1475 4.37419 9.94123L4.51482 9.65059C4.84296 8.95684 5.53671 8.51624 6.30546 8.51624H9.95231C10.7023 8.51624 11.3867 8.94749 11.7242 9.62249L11.8742 9.93184C11.968 10.1475 11.9586 10.4006 11.818 10.5975Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          <div>
            <h2 className="text-base font-semibold">{userName}</h2>
            <p className="text-xs text-gray-500">{userPhone}</p>
            <p className="text-xs text-gray-500">{userEmail}</p>
          </div>
        </div>

        {/* Full Sidebar for larger screens */}
        <div className="bg-white">
          <Sidebar 
            onSidebarItemClick={handleSidebarClick} 
            isSidebarOpen={true}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </div>
      </div>

      {/* Mobile Sidebar - Full Width */}
      <div className={`
        fixed inset-y-0 left-0 z-50 transform 
        ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
        md:hidden 
        w-screen transition-transform duration-300 ease-in-out
      `}>
        <div className="bg-white mt-[176px] w-full">
          <Sidebar 
            onSidebarItemClick={handleSidebarClick} 
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </div>
      </div>

      {/* Overlay for mobile sidebar */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black opacity-0 z-40 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}

      <div className="flex-1 scroll sm:ml-0 sm:mt-0 md:ml-10 -ml-5 -mt-9"> {/*backbutton*/}
        <div className="flex items-center">
          {/* Back Button for Small Screens */}
          <div className="md:hidden mr-3"> {/*titles*/}
  <button 
    onClick={() => setIsSidebarOpen(true)} 
    className="-ml-6 -mt-2 bg-white rounded-full"
  >
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      className="text-red h-25 "  
    >
      <path d="M15 18l-6-6 6-6"/>
    </svg>
  </button>
</div>
          
          <h1 className="text-2xl font-bold text-red mb-3 flex-grow">
            {selectedSidebarItem}
          </h1>
        </div>
        <div className="flex-1">{renderContent()}</div>
      </div>
    </div>
    
  
    {/* {isAddressModalOpen && selectedAddress && ( */}
      <AddressModal
        isOpen={isAddressModalOpen}
        onClose={() => setAddressModalOpen(false)}
        getAddress={getAddress}
        // addressId={addressId}
        cartAddress={getAddress}
        // address={EditAddressData}
        addressId={addressId ? addressId : undefined}
        address={addressId ? EditAddressData : undefined}
      />
      {/* )} */}
    </div>
  );
}
  
  export default ProfilePage;