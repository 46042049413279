import React, { useEffect, useState } from "react";

import im1 from "../../assets/images/Frame 1 (1).png";
import im2 from "../../assets/images/bxs_phone-call.png";
import im3 from "../../assets/images/ic_sharp-email.png";
import im4 from "../../assets/images/carbon_location-filled.png";
import im5 from "../../assets/images/fb.png";
import im6 from "../../assets/images/insta.png";
import im7 from "../../assets/images/yt2.png";
import im8 from "../../assets/images/paperplane.png";

const ContactUs: React.FC = () => {
  const [locationText, setLocationText] = useState(
    "15 College Road College Road, Harrow, England, HA1 1BA"
  );

  useEffect(() => {
    const storedCode = localStorage.getItem("countryCode");
    if (storedCode === "UK") {
      setLocationText("15 College Road College Road, Harrow, England, HA1 1BA");
    } else if (storedCode === "IN") {
      setLocationText(
        "Office No 1210, 12th Floor, Ghanshyam Enclave, Near Lalji Pada Police Station, New Link Road, Kandivali (W), Mumbai 400067, India"
      );
    }
  }, []);

  return (
    <div className="bg-white py-12 px-4 md:px-8">
      <h1 className="lg:text-[35px] text-center -mt-8 font-bold mb-1 text-[24px]">
        Contact Us
      </h1>
      <p className="mb-4 text-center text-[#717171]">
        Any question or remarks? Just write us a message!
      </p>
      <div className="bg-white rounded-lg shadow-md flex flex-col md:flex-row">
        {/* Left Side - Contact Information */}
        <div className="relative md:w-1/3 w-full px-8 py-5 rounded-lg md:mx-3 md:my-3 flex flex-col items-start md:items-start md:justify-center justify-center md:text-left text-center overflow-hidden">
          {/* Background Image */}
          <img
            src={im1}
            alt="Background"
            className="absolute inset-0 w-full h-full object-cover"
          />

          {/* Content */}
          <div className="relative flex flex-col w-full">
            <p className="text-white text-2xl md:text-[30px] font-bold mb-2 md:mb-4 md:text-left text-center md:-mt-8">
              Contact Information
            </p>
            <p className="text-white md:mb-20 md:text-left text-center mb-10">
              We are here to listen :)
            </p>

            {/* Contact Details */}
            <div className="flex flex-col space-y-8 mb-10">
              <div className="flex flex-col items-center text-center md:flex-row md:items-center md:justify-start space-y-2 md:space-y-0 md:space-x-2">
                <img src={im2} alt="Phone" className="w-6 h-6 mr-3" />
                <a href="tel:+919920990771" className="text-white hover:text-gray-200">
                +919920990771
                </a>
              </div>
              <div className="flex flex-col items-center text-center md:flex-row md:items-center md:justify-start space-y-2 md:space-y-0 md:space-x-2">
                <img src={im3} alt="Email" className="w-6 h-6 mr-3 " />
                <a
                  href="mailto:contact@anyfast.com"
                  className="text-white hover:text-gray-200"
                >
                  contact@anyfeast.com
                </a>
              </div>
              <div className="flex flex-col items-center text-center md:flex-row md:items-center md:justify-start space-y-2 md:space-y-0 md:space-x-2">
                <img src={im4} alt="Location" className="w-6 h-6 " />
                <p className="text-white -m-5">{locationText}</p>
              </div>
            </div>

            {/* Social Media Links */}
            <div className="flex space-x-4 md:justify-start justify-center md:mt-40 md:-mb-8">
              <a
                href="https://www.facebook.com/AnyFeast.Official"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={im5}
                  alt="Facebook Icon"
                  className="w-8 h-8 hover:opacity-80"
                />
              </a>
              <a
                href="https://www.instagram.com/anyfeast_official/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={im6}
                  alt="Instagram Icon"
                  className="w-8 h-8 hover:opacity-80"
                />
              </a>
              <a
                href="https://www.youtube.com/@AnyFeast"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={im7}
                  alt="YouTube Icon"
                  className="w-8 h-8 hover:opacity-80"
                />
              </a>
            </div>
          </div>
        </div>
        {/* Right Side - Form */}
 
       <div className="md:w-2/3 p-8 mt-7">
  <form>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* First Name */}
      <div className="relative">
  <label
    htmlFor="firstName"
    className="block text-black font-medium mb-2 transition-all"
  >
    First Name
  </label>
  <input
    type="text"
    id="firstName"
    name="firstName"
    className="w-full  border-b-2 border-[#8D8D8D] bg-transparent text-black focus:outline-none focus:border-gray-700 focus:ring-0 py-2 transition-all"
  />
</div>
{/* Last Name */}
      <div>
        <label htmlFor="lastName" className="block text-black font-medium mb-2">
          Last Name
        </label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          className="w-full border-b-2 border-[#8D8D8D] bg-transparent text-black focus:outline-none focus:border-gray-700 focus:text-black py-2 transition-all"
        />
      </div>
    </div>

    {/* Email and Phone Number */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
      {/* Email */}
      <div>
        <label htmlFor="email" className="block text-black font-medium mb-2">
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className="w-full border-b-2 border-[#8D8D8D] bg-transparent text-black focus:outline-none focus:border-gray-700 focus:text-black py-2 transition-all"
        />
      </div>
      {/* Phone Number */}
      <div>
        <label htmlFor="phoneNumber" className="block text-black font-medium mb-2">
          Phone Number
        </label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          className="w-full border-b-2 border-[#8D8D8D] bg-transparent text-black focus:outline-none focus:border-gray-700 focus:text-black py-2 transition-all"
        />
      </div>
    </div>

{/* Subject */}

<div className="mt-8">
  <label htmlFor="subject" className="block text-black font-medium mb-4">
   Select Subject
  </label>
  <div className="grid grid-cols-2 md:flex md:space-x-6 gap-4">
    <label className="flex items-center">
      <input
        type="radio"
        id="general-inquiry"
        name="subject"
        value="general-inquiry"
        className="peer accent-green md:mr-2"
      />
      <span className="flex items-center cursor-pointer text-[#8D8D8D] peer-checked:text-black">
        General Inquiry
      </span>
    </label>
    <label className="flex items-center">
      <input
        type="radio"
        id="technical-support"
        name="subject"
        value="technical-support"
        className="peer accent-green md:mr-2"
      />
      <span className="flex items-center cursor-pointer text-[#8D8D8D] peer-checked:text-black">
        Technical Support
      </span>
    </label>
    <label className="flex items-center">
      <input
        type="radio"
        id="feedback"
        name="subject"
        value="feedback"
        className="peer accent-green md:mr-2"
      />
      <span className="flex items-center cursor-pointer text-[#8D8D8D] peer-checked:text-black">
        Feedback
      </span>
    </label>
    <label className="flex items-center">
      <input
        type="radio"
        id="other"
        name="subject"
        value="other"
        className="peer accent-green md:mr-2"
      />
      <span className="flex items-center cursor-pointer text-[#8D8D8D] peer-checked:text-black">
        Other
      </span>
    </label>
  </div>
</div>




    {/* Message */}
    <div className="mt-6">
  <label
    htmlFor="message"
    className="block text-black font-medium mb-2 focus:text-black focus:font-bold"
  >
    Message
  </label>
  <textarea
    id="message"
    name="message"
    rows={5}
    placeholder="Write your message..."
    className="w-full border-b-2 border-[#8D8D8D] bg-transparent text-black focus:outline-none focus:border-gray-700 focus:text-black py-2 transition-all"
  ></textarea>
</div>


    {/* Submit Button */}
    <div className="mt-6 flex justify-end items-center flex-wrap md:flex-nowrap">
  {/* Paper Plane Image */}
  <div className="flex items-center md:mr-6 mb-2 md:mb-0 order-2 sm:order-1 md:order-1">
    <img
      src={im8}
      alt="Paper Plane"
      className="w-20 h-20 md:w-44 md:h-44"
    />
  </div>

  {/* Submit Button */}
  <button
    type="submit"
    className="bg-green hover:bg-green-hover text-white font-bold py-2 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 order-1 sm:order-2 md:order-2"
  >
    Send Message
  </button>
</div>


  </form>
</div>
      </div>
    </div>
  );
};

export default ContactUs;
