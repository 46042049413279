import React, { useEffect, useState } from 'react';
import ConfirmRefundPopup from './confirmRefundPopup';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks';
import { cancelByCODAsync, cancelByRozarpayAsync, cancelByStripeAsync, getOrderByUserIdAsync } from '../../../services/reducers/reciepeReducer';

const RefundScreens: React.FC = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false); // For error popup
  const { id } = useParams();
  const userId = localStorage.getItem("userId");
  const dispatch = useAppDispatch();
  const [getOrders, setGetOrders] = useState<any>([]);
  const [selectedReason, setSelectedReason] = useState<string>('');
  const [getRefund, setGetRefund] = useState<number | null>(null);
    const storedCode = localStorage.getItem('countryCode');

  useEffect(() => {
    if (userId) {
      dispatch(getOrderByUserIdAsync(userId)).then((res) => {
        if (res.payload) {
          setGetOrders(res.payload?.data);
        }
      });
    }
  }, [userId]);

  const handleConfirm = () => {
    if (!selectedReason) {
      setShowErrorPopup(true); // Show error popup if no reason is selected
      return;
    }
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
    setShowErrorPopup(false); // Close error popup
  };

  const handleConfirms = () => {
    const filteredOrder = getOrders.find((order: any) => order.id === parseInt(id ?? "", 10));
    if (!filteredOrder) {
      console.error("Order not found");
      return;
    }

    const { payment_method } = filteredOrder;
    const request = {
      reason: selectedReason,
      orderId: id,
    };

    if (payment_method === "cod") {
      dispatch(cancelByCODAsync(request)).then(() => {
        handleClose();
      });
    } else if (payment_method === "stripe") {
      dispatch(cancelByStripeAsync(request)).then(() => {
        handleClose();
      });
    } else if (payment_method === "rozarpay") {
      dispatch(cancelByRozarpayAsync(request)).then(() => {
        handleClose();
      });
    } else {
      console.error("Unsupported payment method:", payment_method);
    }
  };

  useEffect(() => {
    const foundOrder = getOrders.find((order: any) => order.id === parseInt(id ?? "", 10));
    if (foundOrder) {
      setGetRefund(foundOrder.order_total);
    }
  }, [getOrders, id]);

  return (
    <div className="min-h-screen bg-white">
      {/* Main content */}
      <div className="bg-white px-4 py-16 md:px-20 lg:px-40 rounded-lg shadow-transparent w-full md:w-4/5 lg:w-4/5 mx-auto space-y-6 mt-8 font-dm-sans">
        <div className="flex flex-col space-y-6">
          {/* Active Order section */}
          <div className="rounded-lg space-y-5">
            <h2 className="px-3 text-red text-xl font-bold mb-2">Active Order</h2>
            <div className="bg-white space-y-2">
              <div className="h-2"></div>
              {getOrders
                .filter((order: any) => order.id === parseInt(id ?? "", 10))
                .map((order: any) =>
                  <React.Fragment key={order.id}>
                    <p className="font-bold pl-4">Arriving By Today</p>
                    <p className="pl-4">Order Number: # {id}</p>
                    <p className="pl-4">Total {order.currency === "INR" ?"₹" : "£"}{order.order_total}</p>
                    <div className="h-2"></div>
                  </React.Fragment>
                )}
            </div>
          </div>

          {/* Scrollable Order Items */}
          <div className="relative w-full max-w-full bg-white rounded-lg p-4 md:p-8 shadow-lg">
            <button
              className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-greylight rounded-full p-2"
              onClick={() => document.getElementById('scroll-container')?.scrollBy({ left: -120, behavior: 'smooth' })}
            >
              &lt;
            </button>
            <div
              id="scroll-container"
              className="snap-x snap-mandatory overflow-x-auto flex space-x-4 scroll-smooth"
              style={{
                scrollbarWidth: 'none',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {getOrders
                .filter((order: any) => order.id === parseInt(id ?? "", 10))
                .map((order: any) =>
                  order.items?.map((item: any) => (
                    <div key={item.id} className="snap-center flex-shrink-0">
                      <img
                        src={item.info?.image || 'https://via.placeholder.com/100'}
                        alt={`product-${item.id}`}
                        className="w-[120px] h-[106px] object-contain"
                      />
                    </div>
                  ))
                )}
            </div>
            <button
              className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-greylight  rounded-full p-2"
              onClick={() => document.getElementById('scroll-container')?.scrollBy({ left: 120, behavior: 'smooth' })}
            >
              &gt;
            </button>
          </div>

          {/* Reason for Cancellation */}
          <div className="bg-white p-4 md:py-6 rounded-lg">
            <p className="font-bold text-black">Reason for Cancellation</p>
            <p className="text-greytext text-sm">
              Please tell us the correct reason for cancellation. This information is only used to improve our service.
            </p>
          </div>

          {/* Select Reason */}
          <div className="bg-white p-4 md:p-6 rounded-lg space-y-2">
            <p className="font-bold text-black">Select Reason</p>
            {['Incorrect Product', 'Ordered by mistake', 'Want to change product', 'Delayed Delivery Cancellation', 'Duplicate Order'].map((reason, idx) => (
              <div key={idx} className="flex items-center">
                <input
                  type="radio"
                  name="reason"
                  id={`reason${idx}`}
                  value={reason}
                  className="mr-2 accent-green text-greytext"
                  onChange={(e) => setSelectedReason(e.target.value)}
                />
                <label htmlFor={`reason${idx}`} className="text-gray-800">{reason}</label>
              </div>
            ))}
          </div>

          {/* Refund Section */}
          <div className="bg-white p-4 md:p-6 rounded-lg flex justify-between items-center">
            {getOrders
              .filter((order: any) => order.id === parseInt(id ?? "", 10))
              .map((order: any) =>
                <div key={order.id}>
                  <p className="text-gray-800">Refund Amount</p>
                  <p>{storedCode === "IN" ?"₹" : "£"}{order?.order_total}</p>
                </div>
              )}
            <button
              className="bg-green w-2/4 text-white py-2 rounded-md hover:bg-opacity-90"
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>

      {/* Show popup on confirm */}
      {showPopup && (
        <ConfirmRefundPopup onClose={handleClose} onConfirm={handleConfirms} total={getRefund} />
      )}

      {/* Show error popup */}
      {showErrorPopup && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg shadow-lg text-center w-11/12 max-w-md sm:max-w-lg md:max-w-xl mx-auto">
      <p className="text-base sm:text-lg md:text-lg text-greytext">
        Please select a reason for cancellation before confirming.
      </p>
      <button
        className="bg-green text-white py-3 px-6 rounded-md mt-6 hover:bg-green-hover w-full sm:w-2/3 md:w-1/3 mx-auto"
        onClick={handleClose}
      >
        Okay
      </button>
    </div>
  </div>
)}



      {/* Add some space at the bottom */}
      <div className="h-24"></div>
    </div>
  );
};

export default RefundScreens;