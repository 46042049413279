import React, { useEffect, useState } from "react";
import banner2 from "../../assets/images/Biker.png";
import paperbags from "../../assets/images/PaperBag.png";
import check from "../../assets/images/tickSym.png";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import {
  getAdressByUserIdAsync,
  getOrderByUserIdAsync,
} from "../../services/reducers/reciepeReducer";

const OrderConfirmation = () => {
  const [step, setStep] = useState(1);
  const { id } = useParams();
  const [orderDetail, setOrderDetail] = useState<any>([]);
  const navigate = useNavigate();
  const storedCode = localStorage.getItem("countryCode");
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId");
  const userEmail = localStorage.getItem("userEmail") || "";
  const userPhone = localStorage.getItem("userPhone") || "";
  const userName = localStorage.getItem("userName") || "AnyFeast User";
  const [cartAddress, setCartAddress] = useState<any>([]);
   const [getOrders, setGetOrders] = useState<any>([]);
  
    useEffect(() => {
      if (userId) {
        dispatch(getOrderByUserIdAsync(userId)).then((res) => {
          if (res.payload) {
            setGetOrders(res.payload?.data);
          }
        });
      }
    }, [userId]);

  const getAddress = () => {
    if (userId) {
      dispatch(getAdressByUserIdAsync(userId)).then((res) => {
        if (res.payload) {
          setCartAddress(res.payload?.data);
        }
      });
    }
  };
  const handleConfirm = () => {
    navigate("/profile");
    window.scrollTo(0, 0);
  };
//   const filteredOrders = orderDetail.filter(
//     (order: any) => order.status === "processing"
//   );
  
//   const order = orderDetail.find((order: any) => order.id === order.id);

  const handleStepClick = (newStep: React.SetStateAction<number>) => {
    setStep(newStep);
  };
  return (
    <div className="flex flex-col font-dm-sans">
      {/* Header */}
      {/* <div className="w-full bg-green-600 text-white py-4 px-6 shadow-lg">
        <h1 className="text-xl font-semibold">Order Confirmation</h1>
      </div> */}

      {/* Main Content */}
      {getOrders
            .filter((order: any) => order.id === parseInt(id ?? "", 10)) // Ensure comparison works with number or string
            .map((order: any) =>
                <div
            className="flex-grow flex flex-col items-center justify-center px-4 sm:px-6 md:px-8 pt-8 max-w-4xl mx-auto w-full"
          >
            <h2 className="text-2xl font-semibold text-center text-black">
              Thank You
            </h2>
            <p className="text-center text-lg mt-2 text-green flex items-center justify-center">
              <img src={check} alt="Order Placed" className="w-5 h-5 mr-1" />
              Your Order is placed successfully
            </p>
            <p className="text-center text-black mt-2 font-semibold">
              Order Number: #{order.id}
            </p>
            <div
              className="w-full max-w-[260px] h-[260px] mt-4 bg-cover bg-center rounded-md"
              style={{
                backgroundImage: `url(${banner2})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
  
            {/* Delivery Information */}
            <div className="mt-4 border rounded-lg p-4 bg-white w-full">
              <h3 className="font-semibold text-lg">Delivery Address</h3>
              <p className="text-[#5F5F5F] font-bold mt-1">{userName}</p>
              <p className="text-[#5F5F5F]">{userPhone}</p>
              <p className="text-[#5F5F5F]">{order?.shipping_address} {order?.shipping_city} {order?.shipping_state} {order?.shipping_postcode}</p>
  
              {/* Item number */}
              <div className="mt-4 border rounded-lg p-4 bg-[#F8F8F8]">
                <div className="flex flex-col sm:flex-row justify-between justify-items-center items-start">
                  <div className="flex items-start mb-4 sm:mb-0">
                    <img
                      src={paperbags}
                      alt="Total Items"
                      className="w-16 h-16 mr-2"
                    />
                    <div className="flex flex-col">
                      <p className="text-black">
                        Total: {storedCode === "IN" ? "₹" : "£"}{" "}
                        {order.order_total}
                      </p>
                      <p className="text-black font-bold">#{order.id}</p>
                    </div>
                  </div>
  
                  <button
                    className="bg-green w-full sm:w-auto text-white px-4 py-2 rounded hover:bg-green-hover"
                    onClick={() => handleConfirm()}
                  >
                    Track
                  </button>
                </div>
  
                <div className="w-full mt-4">
                  <div className="w-full flex items-center justify-between">
                    {[1, 2, 3].map((i) => (
                      <div
                        key={i}
                        onClick={() => handleStepClick(i)}
                        className={`h-1 flex-1 mx-1 ${
                          step >= i
                            ? "bg-gradient-to-r from-transparent to-green"
                            : "bg-gray-300"
                        } cursor-pointer rounded`}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

            )}
        
      {/* ))} */}
    </div>
  );
};

export default OrderConfirmation;
