import React from "react";

const TermsConditions: React.FC = () => {
  const storedCode = localStorage.getItem('countryCode');
  return (
    <div className=" text-greytext  p-6 lg:p-12 rounded-lg bg-white max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">Terms and Conditions</h1>
      <div className="space-y-6">
        <section>
          <h2 className="text-xl font-semibold mb-4">Access Data</h2>
          <p className="mb-4">
            This agreement applies as between you, a customer, Vendor on the AnyFeast website and our IOS and Android application (collectively
            “Platform”) currently provided or which will be provided by AnyFeast trading as “AnyFeast” (hereinafter “AnyFeast” or “we”).
          </p>
          <p>
            Your agreement to comply with and be bound by these terms and conditions is deemed to occur upon your first use of our services. If you do not
            agree to be bound by these terms and conditions, you should not use our platform.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">Acceptance of Terms & Conditions</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              These Terms set forth a legally binding contract between You and AnyFeast. By using our platform and services, you agree to be bound by these
              Terms.
            </li>
            <li>
              To accept these Terms, you must be at least 18 (eighteen) years of age. In the event that You are below 18 (eighteen) years of age or such
              other older legal age, your guardian must read and understand and accept the provisions on Your behalf.
            </li>
            <li>You also agree to provide true, accurate and complete information about yourself and update them upon any change.</li>
            <li>
              We reserve the right to suspend or terminate your account and refuse any and all current or future use of the service and our platforms (or
              any portion thereof) at any time.
            </li>
            <li>You may access the platforms as available for your personal use only.</li>
            <li>
              You will be required to provide a valid phone number and/or email ID while registering for our services. By providing your phone number or
              email ID to AnyFeast, you consent to be contacted by AnyFeast via phone calls, SMS notifications, and/or emails, in case of any
              subscription/service updates.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">Services</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              AnyFeast offers you a platform which ensures to the best extent possible, to provide food recommendations, grocery recommendations, and
              Marketplace Services.
            </li>
            <li>
              You agree that AnyFeast offers various other additional third-party services through its platform, which you may avail at your own risk.
            </li>
            <li>AnyFeast shall not be liable for any third-party services that you chose to avail on/from the platform.</li>
            <li>
              Any services provided by AnyFeast may be subject to change at the discretion of AnyFeast, and AnyFeast does not require your permission for
              the same. While we want to ensure the best user experience, We do not represent or warrant that the services or our platform will be
              error-free or uninterrupted.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">Marketplace Services</h2>
          <p>
            We use “first party cookies” and “third party cookies.” First party cookies are cookies placed by us. Third party cookies are cookies placed by
            others. Information collected by third-party cookies will be shared with the relevant third party.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">Suspension of Account</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              You agree that AnyFeast may at any time and for any reason, including but not limited to breach of the Terms or the Privacy Policy, terminate
              or restrict or suspend your access to the platform, with or without prior notice, and without liability.
            </li>
            <li>If there is a suspicion of unlawful or illegal activity, AnyFeast may suspend your account immediately.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">Eligibility</h2>
          <p>
            Our services would be available to only select geographies. Persons who are “incompetent to contract” within the meaning of the Indian Contract
            Act, 1872, including undischarged insolvencies, etc., are not eligible to use the Site.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-4">Acceptable Use</h2>
          <p>
            You may use our Service only for lawful purposes. You may not use our Site, App, and our Platform in any way that breaches any applicable local,
            national, or international law or regulation or to send, knowingly receive, upload, download, use, or re-use any material which does not comply
            with our content standards.
          </p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Features</h2>
          <p>

            We may from time to time provide certain features which allow you to interact through our Site, App and our Platform. Generally, we do not moderate any interactive service we provide although we may remove content in contravention of these Terms of Use as set out in section 6. If we do decide to moderate an interactive service, we will make this clear before you use the service and normally provide you with a means of contacting the moderator, should a concern or difficulty arise.
          </p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Intellectual Property Rights</h2>
          <p> Cancellation & Refunds
            Once you place an order on the platform, irrespective of if the payment is made or not, the order cannot be modified, replaced or refunded.
            AnyFeast is not liable to refund, replace, cancel any purchases and is solely up to the merchant/ retailers discretion if they would accept any refunds for the products
          </p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Intellectual Property Rights</h2>
          <p>

            We are the owner of or the licensee of all intellectual property rights in our Site, App and our Platform, and in the material published on it (excluding your Contributions). Those works are protected by copyright laws and treaties around the world. All such rights are reserved. You may not copy, reproduce, republish, download, post, broadcast, transmit, make available to the public, or otherwise use any content on our site in any way except for your own personal, non-commercial use.
          </p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Information Posted</h2>
          <p>

            Commentary and other materials posted on our Service are not intended to amount to advice on which reliance should be placed. We therefore disclaim all liability and responsibility arising from any reliance placed on such materials by any visitor to our Service, or by anyone who may be informed of any of its contents.
          </p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Changes</h2>
          <p>

            We aim to update our Site, App and our Platform regularly and may change the content at any time. If the need arises, we may suspend access to our Site, App and our Platform or close them indefinitely. Any of the material on our Site, App and our Platform may be out of date at any given time, and we are under no obligation to update such material. You agree that we will not be liable to you or any third party for any such modification, editing, deletion, suspension or discontinuance of the platform.
          </p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Our Liability</h2>
          <p>

            We have taken every care in the preparation of our Site, App and our  Platform. However, we will not be responsible for any errors or  omissions in relation to such content or for any technical problems you may experience with our Site, App and our Platform. If we are informed of any inaccuracies on our Site or in our Platform, we will attempt to correct this as soon as we reasonably can. To the extent permitted by law, we exclude all liability (whether arising in contract, in negligence or otherwise) for loss or damage which you or any third party may incur in connection with our Site, our Platform, and any website linked to our Site and any materials posted on it. This does not affect our liability for death or personal injury arising from our negligence, or our liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, or any other liability which cannot be excluded or limited under applicable law.
          </p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Information About You And Your Visits To Our Site And Use Of Our Platform</h2>
          <p>

            We collect certain data about you as a result of you using our Service. This is described in more detail in our Privacy Policy.
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold  italic">Events Outside Our Control</h2>
          No party shall be liable to the other for any delay or non-performance of its obligations under this Agreement arising from any cause beyond its control including, without limitation, any of the following: act of God, governmental act, war, death, fire, flood, explosion or civil commotion. For the avoidance of doubt, nothing shall excuse the Customer from any payment obligations under this Agreement.
        </section>
        <section>
          <h2 className="text-lg font-semibold italic">Waiver</h2>
          Neither you, AnyFeast nor the Merchant/ Retailer shall be responsible to the others for any delay or non-performance of its obligations under this agreement arising from any cause beyond its control including, without limitation, any of the following: act of God, governmental act, war, fire, death, flood, explosion or civil commotion.
        </section>
        <section>

          <h2 className="text-lg font-semibold  italic">Severability</h2>
          If any provision of this agreement is judged to be illegal or unenforceable, the continuation in full force and effect of the remainder of the provisions shall not be prejudiced.

        </section>
        <section>
          <h2 className="text-lg font-semibold italic">Entire Agreement</h2>
          These terms contain the whole agreement between the parties relating to its subject matter and supersede all prior agreements, arrangements and understandings between the parties relating to that subject matter.
        </section>
        <section>

          <h2 className="text-lg font-semibold  italic">Our Right To Vary These Terms And Conditions</h2>
          AnyFeast may revise these terms of use at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we make, as they are binding on you.

        </section>
        <section>
          <h2 className="text-lg font-semibold  italic">No Waiver</h2>
          In the event that any party to these Terms and Conditions fails to exercise any right or remedy contained herein, this shall not be construed as a waiver of that right or remedy.
        </section>
        <section>

          <h2 className="text-lg font-semibold italic">Previous Terms and Conditions</h2>
          In the event of any conflict between these Terms and Conditions and any prior versions thereof, the provisions of these Terms and Conditions shall prevail unless it is expressly stated otherwise.

        </section>
        <section>
          <h2 className="text-lg font-semibold italic">Law and Jurisdiction</h2>
          These terms and conditions and the relationship between you and AnyFeast shall be governed by and construed in accordance with the Law of India and AnyFeast and you agree to submit to the exclusive jurisdiction of the {storedCode === "UK" ? "Courts of London, United Kingdom." : "Courts of Mumbai, India."}
        </section>
      </div>
    </div>
  );
};

export default TermsConditions;
