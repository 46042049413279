import { useState } from "react";
import tickmark from "../../assets/images/tickmark.png";
import { useAppDispatch } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { createPlanRozarpayAsync, createPlanStripeAsync, subScribeBasicAsync } from "../../services/reducers/reciepeReducer";

const PricingPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState("Basic");
  const storedCountry = localStorage.getItem('selectedCountry');
  const storedCode = localStorage.getItem('countryCode');
  const isUK = storedCode === "UK";
  const userId = localStorage.getItem("userId");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const plans = [
    {
      name: "Basic",
      price: isUK ? 0 : 0,
      features: [
        "Free First Delivery.",
        `Free Delivery on orders above ${isUK ? '£30' : '₹599'}`,
        "Basic access to a limited collection of recipes for common ingredients.",
        "Basic AI suggestions for ingredient combinations and simple meal ideas."
      ]
    },
    {
      name: "Standard",
      price: isUK ? 10 : 149,
      features: [
        "10% discount on all meal kit orders.",
        `Free Delivery on orders above ${isUK ? '£20' : '₹299'}`,
        "Access to a broader range of recipes, including more exotic and advanced options.",
        "Advanced AI-driven cooking tips and techniques tailored to the user's scanned ingredients.",
        "Seasonal Recipes Access to a collection of recipes curated for each season.",
        "Receive a special ingredient not available to Basic members, along with unique recipes to try."
       
      ]
    },
    {
      name: "Premium",
      price: isUK ? 20 : 599,
      discount: "Save 50%",
      features: [
        "20% discount on all meal kit orders.",
        "Free Delivery on all orders",
        "Full access to the entire recipe library, including premium and seasonal recipes.",
        "Advanced AI-driven cooking tips and techniques tailored to the user's scanned ingredients.",
        "Seasonal Recipes Access to a collection of recipes curated for each season.",
        "Receive a special ingredient not available to Basic members, along with unique recipes to try.",
        "Save your favourite recipes and share custom recipes with friends and family.",
        "Access to special discounts from partner brands and stores."
       
      ]
    }
  ];

  const handleBasicPlan = () => {
    const request = {
      id: userId,
      subscription_type: 0
    };


    dispatch(subScribeBasicAsync(request)).then(() => {
      navigate(`/cart`);
    });

  };
  const handlePlan = (planId: number) => {
    // const request = {
    //   uid: userId,
    //   subscription_type: planId === 1 ? 1 : 2,
    //   amount: planId === 1 ? 1000 : 2000,
    //   currency: "GBP"

    // };

    // dispatch(createPlanAsync(request)).then((res) => {
    //   navigate(`/cart`);
    // });

    const request = {
      uid: userId,
      subscription_type: planId === 1 ? 1 : 2,
      amount: planId === 1 ? (isUK ? 10 * 100 : 149) : (isUK ? 20 * 100:599),
      currency: isUK ? "GBP" : "INR"

    };
    if (isUK){
      dispatch(createPlanStripeAsync(request)).then((res) => {
        navigate(`/cart`);
      });
    } else {
    dispatch(createPlanRozarpayAsync(request)).then((res) => {
      navigate(`/cart`);
    });
  }
  };
  return (
    <div className="bg-white min-h-screen">
      {/* Mobile and iPad View */}
      <div className="lg:hidden"> 
        {/* Content */}
        <div className="px-4 pb-6">
          <h2 className="text-xl font-semibold mb-2 mt-6">Choose a Plan</h2>
          <p className="text-sm text-gray-600 mb-6">Basic, Standard or Premium? It's your call</p>

          {/* Plan Selection Cards */}
          <div className="space-y-3 mb-6">
            {plans.map((plan) => (
              <div
                key={plan.name}
                onClick={() => setSelectedPlan(plan.name)}
                className={`rounded-lg p-4 border ${
                  selectedPlan === plan.name
                    ? "border-2 border-green-700"
                    : "border-gray-200"
                }`}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <div className="flex items-center gap-2">
                      <h3 className="text-lg">{plan.name}</h3>
                      {plan.discount && (
                        <span className="text-sm text-orange-500 font-medium">
                          {plan.discount}
                        </span>
                      )}
                    </div>
                    <p className="text-base mt-1">
                    {storedCode === "IN" ?"₹" : "£"}{plan.price} <span className="text-gray-600">/Month</span>
                    </p>
                  </div>
                  <div className={`w-6 h-6 rounded-full border-2 ${
                    selectedPlan === plan.name
                      ? "border-green-700 bg-green-700"
                      : "border-gray-300"
                  } flex items-center justify-center`}>
                    {selectedPlan === plan.name && (
                      <div className="w-2 h-2 rounded-full bg-white" />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Features List */}
          <div className="space-y-4 mb-8">
            {selectedPlan && plans.find(p => p.name === selectedPlan)?.features.map((feature, idx) => (
              <div key={idx} className="flex items-start gap-3 pb-4 border-b border-gray-200">
                <div className="w-5 h-5 rounded-full bg-orange-500 flex-shrink-0 flex items-center justify-center mt-0.5">
                  <svg className="w-3 h-3 text-white" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 3L4.5 8.5L2 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <span className="text-sm text-gray-600">{feature}</span>
              </div>
            ))}
          </div>

          {/* Buy Button */}
          <button className="w-full py-3 bg-green-700 text-white rounded-lg font-medium">
            {selectedPlan === "Basic" ? "Free Subscription" : `Buy Now ${storedCode === "IN" ?"₹" : "£"}${plans.find(p => p.name === selectedPlan)?.price}`}
          </button>
        </div>
      </div>

      {/* Desktop View (Large screens only) */}
      <div className="hidden lg:block">
        {/* Hero Section */}
        <section className="text-center my-12">
          <h1 className="text-4xl md:text-5xl text-[#313131] p-4 font-bold">Upgrade to Premium</h1>
          <p className="text-lg md:text-3xl text-[#313131] p-2 -mt-3">
            Enjoy our special discounts, exclusive perks, and many more benefits with our
          </p>
          <p className="text-lg md:text-3xl">premium plans!</p>
        </section>

        {/* Pricing Plans */}
        <div className="container px-4 md:px-20  mb-10 md:m-0 md:mx-auto">
          <div className="flex flex-col md:flex-row justify-center items-center md:items-stretch gap-6 md:gap-0 relative">
            {/* Basic Plan */}
            <div className="w-full md:w-1/3 border border-[#FF8A00] hover:border-[#5CAA72] rounded-lg px-6 py-9 shadow-lg text-center bg-white z-0 transition-all duration-1000 hover:z-20 hover:-translate-y-4 md:absolute md:left-[1%] md:transform md:translate-y-8">
              <h2 className="text-3xl font-bold">Basic</h2>
              <p className="text-2xl mt-4">
              {storedCode === "IN" ?"₹" : "£"}<span className="text-5xl font-bold">{isUK ? 0 : 0}</span>/m
              </p>
              <ul className="mt-6 text-md font-extralight space-y-4 text-[#313131] text-left ">
                {plans[0].features.map((feature, idx) => (
                  <li key={idx} className="flex items-start border-b border-gray-200 pb-4">
                    <img src={tickmark} alt="Tick Mark" className="h-6 w-6 mr-4" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <button className="w-11/12 py-2 mt-44 bg-[green] text-base text-white rounded-lg hover:bg-green-hover transition-colors duration-300"  onClick={() => handleBasicPlan()}>
                Free Subscription
              </button>
            </div>

            {/* Standard Plan */}
            <div className="w-full md:w-1/3 border border-[#FF8A00] hover:border-[#5CAA72] rounded-lg p-6 mb-9 shadow-2xl text-center bg-white z-10 relative transition-all duration-1000 hover:-translate-y-4" >
              <h2 className="text-4xl font-bold">Standard</h2>
              <p className="text-2xl mt-4">
              {storedCode === "IN" ?"₹" : "£"}<span className="text-5xl font-bold">{isUK ? 10 : 149}</span>/m
              </p>
              <ul className="mt-6 text-xs space-y-4 text-[#313131] text-left font-extralight">
                {plans[1].features.map((feature, idx) => (
                  <li key={idx} className="flex items-start border-b border-gray-200 pb-4">
                    <img src={tickmark} alt="Tick Mark" className="h-6 w-6 mr-4" />
                    <span className="text-base">{feature}</span>
                  </li>
                ))}
              </ul>
              <button className="w-11/12 py-2 mt-20 mb-8 bg-[green] text-base text-white rounded-lg hover:bg-green-hover transition-colors duration-300"  onClick={() => handlePlan(1)}>
                Buy Now {storedCode === "IN" ?"₹" : "£"}{isUK ? 10 : 149}
              </button>
            </div>

            {/* Premium Plan */}
            <div className="w-full md:w-1/3 border border-[#FF8A00] hover:border-[#5CAA72] rounded-lg px-6 py-6 shadow-lg text-center bg-white z-0 transition-all duration-1000 hover:z-20 hover:-translate-y-4 md:absolute md:right-[1%] md:transform md:translate-y-8">
              <h2 className="text-3xl font-bold">Premium</h2>
              <p className="text-2xl mt-2">
              {storedCode === "IN" ?"₹" : "£"}<span className="text-3xl font-bold">{isUK ? 20 : 599}</span>/m
              </p>
              <ul className="mt-6 text-xs text-[#313131] font-extralight  space-y-1 text-left">
                {plans[2].features.map((feature, idx) => (
                  <li key={idx} className="flex items-start border-b border-gray-200 pb-4">
                    <img src={tickmark} alt="Tick Mark" className="h-6 w-6 mr-4" />
                    <span className="text-base">{feature}</span>
                  </li>
                ))}
              </ul>
              <button className="w-11/12 py-2 mt-0 mb-1 bg-[green] text-base text-white rounded-lg hover:bg-green-hover transition-colors duration-300"  onClick={() => handlePlan(2)}>
                Buy Now {storedCode === "IN" ?"₹" : "£"}{isUK ? 20 : 599}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PricingPlans;