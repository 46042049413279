import React from "react";
import blog2 from "../../../src/assets/images/b2.png";
import blog3 from "../../../src/assets/images/b3.png";
import blog4 from "../../../src/assets/images/b4.png";
import blog5 from "../../../src/assets/images/b5.png";
// import blog6 from "../../../src/assets/images/blog6.png";
// import blog7 from "../../../src/assets/images/blog7.png";
import blog6 from "../../assets/images/b6.png";
import blog7 from "../../assets/images/b7.png";
import { Link } from "react-router-dom";
import moment from "moment";

interface Blogs {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  date: string;
  time: string;
}

const blogs: Blogs[] = [
  {
    id: 1,
    title: "The Secret to Perfectly Balanced Meals: How AnyFeast Chooses Ingredients and Designs Recipe Kits ",
    description:
      "In today’s fast-paced world, finding time to prepare wholesome, balanced meals can feel like a challenge. At AnyFeast, we’ve turned that challenge into a passion project...",
    imageUrl: blog2,
    date: "December 6, 2022",
    time: "5 min",
  },
  {
    id: 2,
    title: "Creating Memorable Meals at Home: How AnyFeast Makes Cooking an Experience",
    description: "In today’s fast-paced world, finding the time and inspiration to cook can be a challenge. That’s where AnyFeast, a pioneer in personalized and sustainable meal kits, steps in...",
    imageUrl: blog3,
    date: "November 3, 2024",
    time: "5 min",
  },
  {
    id: 3,
    title: "Supporting Local Stores in the Age of Quick Commerce: A Call for Collective Action",
    description:
      "In recent years, the rapid rise of quick commerce has disrupted the retail landscape, causing significant challenges for traditional businesses. One of the most notable impacts has...",
    imageUrl: blog4,
    date: "November 24, 2024",
    time: "5 min",
  },
  {
    id: 4,
    title: "Top 10 Weeknight Dinners to Simplify Your Busy Week with AnyFeast",
    description:
      "Finding the time to cook during a busy week can feel overwhelming. That’s where AnyFeast steps in—designed to make weeknight dinners simple, quick, and satisfying...",
    imageUrl: blog5,
    date: "November 29, 2024",
    time: "5 min",
  },
  {
    id: 5,
    title: "Faces Behind the Feast: Celebrating Local Artisans in a Global Kitchen",
    description:
      "In a world dominated by mass production and convenience, it’s easy to forget the origins of our food. Behind every ingredient in your pantry, behind every meal you cook, there is a story—",
    imageUrl: blog6,
    date: "November 31, 2024",
    time: "5 min",
  },
  {
    id: 6,
    title: "Ways AnyFeast Can Help You Stick to Your Dietary Goals",
    description:
      "In today’s busy world, maintaining dietary goals can feel overwhelming. Between tight schedules and endless food options, it’s easy to fall off track. That’s where AnyFeast comes in—",
    imageUrl: blog7,
    date: "November 4, 2024",
    time: "5 min",
  },
];
interface Props {
  blogs: any;
}
const BlogPost: React.FC<Props> = ({ blogs }) => {
  const convertDateWithMoment = (isoDate: string): string => {
    return moment(isoDate).format("MMMM D, YYYY");
  };
  
  return (
    // <div className="pt-16">
    //   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 space-x-8 justify-items-center">
    //     {blogs.map((blog: any) => (
    //       <Link to={`/blogPost/${blog.id}`}>
    //         <div key={blog.id} className="bg-white rounded-lg border border-1 w-full">
    //           <img
    //             src={blog.featured_image_url}
    //             alt={blog.title}
    //             className="w-full h-[300px] object-cover rounded-t-lg mb-4"
    //           />

    //           <p className="px-3 mb-10">
    //             <div className="text-sm text-[#172B4D] my-2">
    //               <span>{convertDateWithMoment(blog.
    //                 post_date)
    //               }</span> | <span>{blog.reading_time_minutes
    //               } min read</span>
    //             </div>
    //             <h2 className="text-lg font-bold mb-5 line-clamp-2">{blog.title}</h2>
    //             <p className="text-sm">{blog.description}</p>
    //           </p>
    //         </div>
    //       </Link>
    //     ))}
    //   </div>
    // </div>
    <div className="pt-16">

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
        {blogs.map((blog: any) => (
          <Link to={`/blogPost/${blog.title}`} key={blog.id}>
            <div className="bg-white rounded-lg border border-1 w-full h-full flex flex-col">
              <div className="relative  h-[300px]">
                <img
                  src={blog.featured_image_url}
                  alt={blog.title}
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-t-lg"
                />
              </div>


              <div className="flex flex-col justify-between flex-grow p-3">
                <div className="text-sm text-[#172B4D] my-2">
                  <span>{convertDateWithMoment(blog.post_date)}</span> 
                  {/* | <span>{blog.reading_time_minutes} min read</span> */}
                </div>
                <h2 className="text-lg font-bold mb-4 line-clamp-2"> {blog.title?.replace(/!¬†¬†|¬†/g, '.')}</h2>
                <p className="text-sm line-clamp-3">{blog.description}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogPost;
