import React from "react";
import offer from "../../../src/assets/images/offer.png";
import star from "../../../src/assets/images/star.png";

const TopHeader = () => {
    const storedCode = localStorage.getItem('countryCode');
    let offerText = "₹200 OFF on your First Order.";
    if (storedCode === "UK") {
      offerText = "£20 OFF on your First Order - WELCOME20";
    } else if (storedCode === "IN") {
      offerText = "₹200 OFF on your First Order - WELCOME200"; 
    }
  return (
    <>
     <div className="flex flex-wrap justify-center items-center bg-black text-white">
        <span className="mx-2 sm:mx-4">
          <img src={offer} alt="offer" className="w-6 sm:w-8 lg:w-6" />
        </span>
        <span className="px-2 font-sans text-center sm:text-left sm:px-4 text-sm sm:text-base lg:text-lg">
          {offerText}
        </span>
        <span className="mx-2 sm:mx-4">
          <img src={star} alt="star" className="w-4 sm:w-6 lg:w-6" />
        </span>
      </div>
      {/* <div className="flex justify-end items-center bg-[#F5F5F5] text-white">
        <span className="">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 11.25H14.25L16.5 16.5H1.5L3.75 11.25H6.75M9.75 6C9.75 6.19891 9.67098 6.38968 9.53033 6.53033C9.38968 6.67098 9.19891 6.75 9 6.75C8.80109 6.75 8.61032 6.67098 8.46967 6.53033C8.32902 6.38968 8.25 6.19891 8.25 6C8.25 5.80109 8.32902 5.61032 8.46967 5.46967C8.61032 5.32902 8.80109 5.25 9 5.25C9.19891 5.25 9.38968 5.32902 9.53033 5.46967C9.67098 5.61032 9.75 5.80109 9.75 6ZM4.5 6C4.5 9.75 9 13.5 9 13.5C9 13.5 13.5 9.75 13.5 6C13.5 3.43725 11.4855 1.5 9 1.5C6.5145 1.5 4.5 3.43725 4.5 6Z"
              stroke="#313131"
              stroke-width="2"
            />
          </svg>
        </span>
        <span className="px-2 font-sans text-black ">
          <select className="text-sm font-sans" defaultValue="Bangalore, Siri Anjaneya Temple">
            <option value="Bangalore, Siri Anjaneya Temple">Bangalore, Siri Anjaneya Temple</option>
            <option value="Bangalore, Siri Anjaneya Temple">Bangalore, Siri Anjaneya Temple</option>
          </select>
        </span>
       
      </div> */}
    </>
  );
};

export default TopHeader;
