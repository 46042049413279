import React, { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import im1 from "../../../src/assets/images/ChatCircle.png";
import im2 from "../../../src/assets/images/Export.png";
import cameraIcon from "../../../src/assets/images/Camera.png";
import documentIcon from "../../../src/assets/images/Paperclip.png";
import gifIcon from "../../../src/assets/images/Gif.png";
import replyIcon from "../../../src/assets/images/ChatCircle.png";
import profileImage from "../../../src/assets/images/anna1.png";
import profile from "../../../src/assets/images/anna2.png";
import closeIcon from "../../../src/assets/images/X.png";
import deleteIcon from "../../../src/assets/images/delete.png";
import arrowUpIcon from "../../../src/assets/images/ua.png"; 
import arrowDownIcon from "../../../src/assets/images/da.png";

interface Comment {
  id: number;
  user: string;
  text: string;
  likes: number;
  time: Date;
  isLiked: boolean;
  replies: { user: string; text: string; time: Date }[];
  isRepliesVisible: boolean;
}

const CommentSection: React.FC = () => {
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(44);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [comments, setComments] = useState<Comment[]>([
    { 
      id: 1, 
      user: "Anna", 
      text: "I am an aspiring blog writer. Do you have any helpful hints for beginners?",
      likes: 2, 
      time: new Date(), 
      isLiked: false,
      replies: [],
      isRepliesVisible: false
    },
  ]);
  const [newComment, setNewComment] = useState("");
  const [commentCount, setCommentCount] = useState(comments.length);
  const [replyingTo, setReplyingTo] = useState<number | null>(null);
  const [replyText, setReplyText] = useState("");

  const toggleLike = () => {
    setLikeCount(liked ? likeCount - 1 : likeCount + 1);
    setLiked(!liked);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCommentSubmit = () => {
    if (newComment.trim()) {
      setComments([
        ...comments,
        {
          id: Date.now(),
          user: "Anna",
          text: newComment,
          likes: 0,
          time: new Date(),
          isLiked: false,
          replies: [],
          isRepliesVisible: false
        },
      ]);
      setNewComment("");
      setCommentCount(commentCount + 1);
    }
  };

  const handleReplySubmit = (commentId: number) => {
    if (replyText.trim()) {
      setComments(
        comments.map((comment) =>
          comment.id === commentId
            ? {
                ...comment,
                replies: [
                  ...comment.replies,
                  {
                    user: "Anna",
                    text: replyText,
                    time: new Date(),
                  },
                ],
                isRepliesVisible: true // Make replies visible when a new reply is added
              }
            : comment
        )
      );
      setReplyText("");
      setReplyingTo(null); // Close reply box after submitting
    }
  };

  const handleDeleteReply = (commentId: number, replyIndex: number) => {
    setComments(
      comments.map((comment) =>
        comment.id === commentId
          ? {
              ...comment,
              replies: comment.replies.filter((_, index) => index !== replyIndex),
            }
          : comment
      )
    );
  };

  const handleDeleteComment = (commentId: number) => {
    setComments(comments.filter((comment) => comment.id !== commentId));
    setCommentCount(commentCount - 1);
  };

  const toggleCommentLike = (id: number) => {
    setComments(
      comments.map((comment) =>
        comment.id === id
          ? {
              ...comment,
              isLiked: !comment.isLiked,
              likes: comment.isLiked ? comment.likes - 1 : comment.likes + 1,
            }
          : comment
      )
    );
  };

  const toggleRepliesVisibility = (commentId: number) => {
    setComments(
      comments.map((comment) =>
        comment.id === commentId
          ? {
              ...comment,
              isRepliesVisible: !comment.isRepliesVisible
            }
          : comment
      )
    );
  };

  return (
    <div className="mt-4 relative">
      {/* Like and Comment Section */}
      <div className="flex items-center justify-between py-4">
        <div className="flex items-center space-x-6">
          <div
            onClick={toggleLike}
            className={`flex items-center space-x-1 cursor-pointer ${liked ? 'text-[#E54D40]' : 'text-gray-500'}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill={liked ? 'currentColor' : 'none'}
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4.318 6.318a4.5 4.5 0 016.364 0L12 7.636l1.318-1.318a4.5 4.5 0 116.364 6.364L12 20.364l-7.682-7.682a4.5 4.5 0 010-6.364z"
              />
            </svg>
            <span className="text-sm font-medium text-black">{likeCount} Likes</span>
          </div>

          {/* Comment Button */}
          <div onClick={toggleSidebar} className="flex items-center space-x-1 cursor-pointer">
            <img src={im1} alt="Comment" className="w-6 h-6" />
            <span className="text-sm font-medium text-black">{commentCount} Comments</span>
          </div>
        </div>

        {/* Share Button */}
        <div className="flex items-center space-x-1 cursor-pointer">
          <img src={im2} alt="Share" className="w-6 h-6" />
          <span className="text-sm font-medium text-black">Share</span>
        </div>
      </div>

      {/* Divider Line */}
      <hr className="border-t border-[#CCCCCC]" />

      {/* Sidebar and Overlay */}
      {isSidebarOpen && (
        <>
          {/* Dark overlay */}
          <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={toggleSidebar}></div>

          {/* Sidebar */}
          <div className="fixed right-0 top-0 h-full w-96 bg-white shadow-lg z-20 p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold text-black">Comments ({commentCount})</h2>
              <button onClick={toggleSidebar} className="text-gray-600 hover:text-gray-900">
                <img src={closeIcon} alt="Close" className="w-6 h-6" />
              </button>
            </div>

            {/* Comment Box */}
            <div className="border p-2 shadow-md rounded-lg mb-8">
              <div className="flex items-center space-x-2 mb-2">
                <img src={profileImage} alt="Profile" className="w-8 h-8 rounded-full" />
                <span className="font-bold text-md">Anna</span>
              </div>
              <input
                type="text"
                placeholder="Write a comment..."
                className="w-full text-sm text-black outline-none p-2 mb-2"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
              <div className="flex justify-between items-center">
                <div className="flex space-x-2">
                  <img src={cameraIcon} alt="Camera" className="w-5 h-5 cursor-pointer" />
                  <img src={documentIcon} alt="Document" className="w-5 h-5 cursor-pointer" />
                  <img src={gifIcon} alt="GIF" className="w-5 h-5 cursor-pointer" />
                </div>
                <button
                  onClick={handleCommentSubmit}
                  disabled={!newComment.trim()}
                  className={`px-4 py-2 text-sm rounded ${newComment.trim() ? 'bg-green hover:bg-green-hover text-white' : 'bg-[#C1C1C1] text-white cursor-not-allowed'}`}
                >
                  Publish
                </button>
              </div>
            </div>

            {/* Comment Count */}
            <div className="text-sm text-black text-md font-semibold mb-2">
              <span>{commentCount} Comments</span>
            </div>

            {/* Divider Line */}
            <hr className="border-t border-[#F2F2F2] mb-4" />

            {/* Comments Section */}
            <div className="space-y-2">
              {comments.map((comment, commentIndex) => (
                <div key={comment.id}>
                  <div className="flex items-start space-x-2">
                    <img src={profile} alt="User" className="w-8 h-8 rounded-full" />
                    <div>
                      <div className="font-medium text-md text-black">{comment.user}</div>
                      <div className="text-[#AEAEAE] text-sm">
                        {formatDistanceToNow(comment.time)} ago
                      </div>
                    </div>
                  </div>
                  <div className="ml-10 text-blacktext-[15px] font-light">{comment.text}</div>
                  <div className="ml-10 flex items-center space-x-4 text-[#AEAEAE] text-sm">
                    <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={() => toggleCommentLike(comment.id)}
                    >
                      <span className={comment.isLiked ? "text-red-500" : "text-[#AEAEAE]"}>
                        {comment.isLiked ? '❤️' : '🤍'}
                      </span>
                      <span>{comment.likes} Like</span>
                    </div>
                    <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={() => setReplyingTo(replyingTo === comment.id ? null : comment.id)}
                    >
                      <img src={replyIcon} alt="Reply" className="w-4 h-4" />
                      <span>Reply</span>
                    </div>
                    <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={() => handleDeleteComment(comment.id)}
                    >
                      <img src={deleteIcon} alt="Delete Comment" className="w-4 h-4 text-red-500" />
                      <span className="text-[#AEAEAE]">Delete</span>
                    </div>
                    <div
                      className="flex items-center space-x-1 cursor-pointer"
                      onClick={() => toggleRepliesVisibility(comment.id)}
                    >
                      <img src={comment.isRepliesVisible ? arrowUpIcon : arrowDownIcon} alt={comment.isRepliesVisible ? 'Hide Replies' : 'Show Replies'} className="w-4 h-4" />
                    </div>
                  </div>

                  {/* Replies Section */}
                  {comment.isRepliesVisible && (
  <div className="mt-2 space-y-2 pl-8">
    {comment.replies.map((reply, index) => (
      <div key={index} className="flex items-start space-x-2 text-sm">
        <img src={profileImage} alt="Profile" className="w-6 h-6 rounded-full" />
        <div>
          <div className="flex flex-col">
            <span className="font-normal">{reply.user}</span>
            <span className="text-[#AEAEAE] text-xs">
              {formatDistanceToNow(reply.time)} ago
            </span>
          </div>
          <p className="text-black mt-1">{reply.text}</p>
        </div>
        <button
          onClick={() => handleDeleteReply(comment.id, index)}
          className="ml-2 text-red-500"
        >
          <img src={deleteIcon} alt="Delete" className="w-3 h-3" />
        </button>
      </div>
    ))}
  </div>
)}

                  {/* Reply Input */}
                  {replyingTo === comment.id && (
                    <div className="mt-2 ml-10 flex items-center space-x-2">
                      <input
                        type="text"
                        placeholder="Reply here..."
                        className="w-full outline-none p-2 border border-[#F2F2F2] rounded-lg text-sm"
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                      />
                      <button
                        onClick={() => handleReplySubmit(comment.id)}
                        disabled={!replyText.trim()}
                        className={`px-[8px] py-[5px] text-sm rounded ${replyText.trim() ? 'bg-green hover:bg-green-hover text-white' : 'bg-gray-300 text-gray-600 cursor-not-allowed'}`}
                      >
                        Add
                      </button>
                    </div>
                  )}

                  {/* Separator Line */}
                  <div className={`mt-4 border-b border-[#F2F2F2] ${comment.replies.length > 0 ? 'mt-6' : ''}`} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CommentSection;