import React from "react";

const CancellationRefunds: React.FC = () => {
  return (
    <div className=" min-h-screen py-8 px-4  bg-white sm:px-8">
      <div className="max-w-6xl mx-auto  rounded-lg p-6">
        <div className="text-gray-700 space-y-6">
          <section>
            <h2 className="text-2xl font-semibold text-greytext mb-2">
              Cancellation
            </h2>
            <p>
              As a general rule, you shall not be entitled to cancel your order
              once you have received confirmation of the same.
            </p>
            <p>
              However, in the unlikely event of an item on your order being
              unavailable, we will contact you on the phone number provided to
              us at the time of placing the order and inform you of such
              unavailability. In such an event, you will be entitled to cancel
              the entire order and shall be entitled to a refund in accordance
              with our refund policy.
            </p>
            <p>We reserve the sole right to cancel your order in the following circumstances:</p>
            <ul className="list-disc list-inside ml-4">
              <li>
                In the event of the designated address falling outside the
                delivery zone offered by us;
              </li>
              <li>
                Failure to contact you by phone or email at the time of
                confirming the order booking;
              </li>
              <li>
                Failure to deliver your order due to lack of information,
                direction, or authorization from you at the time of delivery;
              </li>
              <li>
                Unavailability of all the items ordered by you at the time of
                booking the order;
              </li>
              <li>Failure due to reasons beyond our control or attributable to the Vendor.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-greytext  mb-2">
              Refunds
            </h2>
            <p>
              In case of delivery of order being late, bad, or wrong, AnyFeast
              adheres to a very high standard of quality of services to maintain
              a high level of customer satisfaction.
            </p>
            <p>
              Hence, in case of an order being late or bad or wrong order
              processed related issue of AnyFeast, we generally do not charge
              the customer for the order. All the refunds will be made to the
              customer’s bank account in 5-7 days.
            </p>
            <p>
              This is totally up to the discretion of AnyFeast. Refund Policy in
              detail is as follows:
            </p>
            <p>You shall be entitled to a refund only if you pre-pay for your order and:</p>
            <ul className="list-disc list-inside ml-4">
              <li>Your order packaging has been tampered with or damaged at the time of delivery;</li>
              <li>
                Cancelling your order due to your delivery location falling
                outside our designated delivery areas;
              </li>
              <li>
                Failure to contact you by phone or email at the time of
                confirming the order booking;
              </li>
              <li>
                You cancel the order at the time of confirmation due to
                unavailability of the items you ordered for at the time of
                booking;
              </li>
              <li>
                Failure to deliver your order due to lack of information,
                direction, or authorization from you at the time of delivery;
              </li>
              <li>
                Unavailability of all the items ordered by you at the time of
                booking the order.
              </li>
            </ul>
            <p>
              Our decision on refunds shall be at our sole discretion and shall
              be final and binding.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-greytext  mb-2">
              Customer Responsibilities
            </h2>
            <p>
              We advise customers to open their parcel as soon as they receive
              it. All fresh vegetables should be stored in a refrigerator, and
              any frozen items should be put into a freezer upon delivery.
            </p>
            <p>
              If you are not happy with any item, please get in touch with our
              Customer Service Team within 24 hours of receiving the parcel by
              emailing <a href="mailto:contact@anyfeast.com" className="text-blue-600 underline">contact@anyfeast.com</a>.
            </p>
            <p>Photo proof is needed for any quality complaints.</p>
            <p>Customers are requested to sign for accepting the delivery.</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CancellationRefunds;
