import React from "react";
import im1 from "../../assets/images/cross.png";
 
interface ModalProps {
  isOpen: boolean;
  setSelectedCuisines: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCuisines: string[];
  onClose: () => void;
}
 
const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  setSelectedCuisines,
  selectedCuisines,
}) => {
  if (!isOpen) return null;
 
  const handleCuisineChange = (cuisine: string) => {
    setSelectedCuisines((prevCuisines) =>
      prevCuisines.includes(cuisine)
        ? prevCuisines.filter((item) => item !== cuisine)
        : [...prevCuisines, cuisine]
    );
  };
 
  const handleClearAll = () => {
    setSelectedCuisines([]);
  };
 
  const handleApply = () => {
    console.log("Applied Cuisines: ", selectedCuisines);
    onClose();
  };
 
  const cuisinesList = [
    "Indian",
    "Chinese",
    "Italian",
    "French",
    "Mexican",
    "American",
    "English",
  ];
 
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-6 xl:w-1/3 lg:w-1/3 md:w-1/3 w-3/4">
        <div className="flex justify-between items-center -ml-6 -mr-6 -mt-6 p-3 bg-[#FAFCFE] rounded-lg">
          <h2 className="text-xl font-semibold font-sans ml-2 text-black">
            Cuisines
          </h2>
          <button onClick={onClose}>
    <img
      src={im1}
      alt="Close"
      className="w-4 h-4 mr-2"
    />
  </button>
        </div>
        <ul className="mt-4">
          {cuisinesList.map((cuisine) => (
            <li
              key={cuisine}
              className="flex items-center mt-6 font-sans text-black "
            >
            <input
  type="checkbox"
  className="mr-2 accent-green "
  checked={selectedCuisines.includes(cuisine)}
  onChange={() => handleCuisineChange(cuisine)}
/>
 
              {cuisine}
            </li>
          ))}
        </ul>
        <div className="flex justify-end items-center mt-6">
  <button
    onClick={handleClearAll}
    className="font-sans border-2 border-green text-green hover:bg-green hover:text-white rounded-md w-24 h-10 md:w-24 md:h-10 flex items-center justify-center"
  >
    Clear All
  </button>
  <button
    onClick={handleApply}
    className="bg-green text-white font-sans rounded-md w-24 h-10 md:w-24 md:h-10 hover:bg-green-hover  flex items-center justify-center ml-2"
  >
    Apply
  </button>
</div>
 
 
      </div>
    </div>
  );
};
 
export default Modal;
 