import React, { useEffect, useState } from "react";
//import BigBanner from "../../../src/assets/images/ChristmasBigBanner.png"; 
import BigBanner from "../../../src/assets/images/valen4.png";
import ReceipeKit from "./ReceipeKit";
import { getFestiveDishesAsync } from '../../services/reducers/reciepeReducer';
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks";

const ChristmasSpecial = () => {
  const dispatch = useAppDispatch();
  const [festiveDetails, setFestiveDetails] = useState<any>([]);
  const [displayedIds, setDisplayedIds] = useState<string[]>([]);
  const storedCode = localStorage.getItem('countryCode');

  // useEffect(() => {
  //   getFestiveDishes();
  // }, []);

  // const getFestiveDishes = () => {
  //   const request = {
  //     country: storedCode,

  //   }
  //   dispatch(getFestiveDishesAsync(request)).then((res) => {
  //     if (res.payload) {
  //       setFestiveDetails(res.payload);
  //       if (Array.isArray(res.payload?.data?.recipes)) {
  //         const ids = res.payload?.data.recipes.map((item: any) => item.id);
  //         setDisplayedIds(ids);
  //       } else {
  //         console.error('Recipes array is missing or not an array:', res.payload?.data?.recipes);
  //       }
  //     }
  //   });
  // };

  return (
    <div>
      {/* <div className="w-full   flex flex-col items-center -ml-8 md:-ml-28">
        <img 
          src={BigBanner} 
          alt="Christmas Banner" 
          className="w-full max-w-max h-auto object-cover rounded-lg"
        />
      </div> */}
       <div className="flex w-full justify-center mt-4 md:mt-8">
    <img 
      src={BigBanner} 
      alt="Christmas Banner" 
      className="h-auto w-full max-w-4xl rounded-lg" 
    />
  </div>
      <div className="px-4 md:px-12 lg:px-48 mb-12 mt-8">
        <p className="text-center text-black text-xl md:text-3xl lg:text-4xl font-semibold leading-relaxed">
          Craft Delicious Memories with Exclusive Holiday Recipe Kits and Special Festive Offers!
        </p>
      </div>
      
      {/* <FestivalKit selectedRecipe={"Lunch"} type={"F"} /> */}
      <ReceipeKit type={"Festival"} festiveDetails={festiveDetails} displayedIds={displayedIds} />
    </div>
  );
};

export default ChristmasSpecial;
