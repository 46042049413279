import React, { useEffect, useState } from "react";
import "../../App.css";
import ProductCard from "./productCard";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { getSingleProductAsync } from "../../services/reducers/reciepeReducer";
import { request } from "node:http";

const ProductPage: React.FC = () => {
  const { id } = useParams();
  const { url } = useParams();
  const dispatch = useAppDispatch();
  const [productDetail, setProductDetail] = useState([]);
  const storedCountry = localStorage.getItem('selectedCountry');
  const storedCode = localStorage.getItem('countryCode');

  useEffect(() => {
    if (storedCode) {
      getProductDetail();
    }
  }, [storedCode]);

  useEffect(() => {
    getProductDetail();
  }, [id]);

  const getProductDetail = () => {

    // dispatch(getSingleProductAsync(request)).then((res) => {
    //   if (res.payload) {
    //     setProductDetail(res.payload.data);
    //   }
    // });

    if (url) {
          const request = {
            url: url,
            country: storedCode,
            id: id,
          };
          dispatch(getSingleProductAsync(request)).then((res) => {
            if (res.payload) {
             setProductDetail(res.payload.data)
            }
          }
        );
        }
  };
  console.log(productDetail, "productDetail")
  return (
    <div className="container mx-auto p-4 overflow-y">
      <div>
        <ProductCard productDetail={productDetail}/>
        {/* <div className="flex items-center justify-center mt-10 sm:mt-6 mb-10 sm:mb-6 md:mt-20">
          <div className="flex-grow h-px bg-black"></div>
          <span className="mx-5 sm:mx-10 text-black text-xl sm:text-[20px] font-semibold whitespace-nowrap">
            Related Products
          </span>
          <div className="flex-grow h-px bg-black"></div>
        </div> */}

        {/* related products */}
        {/* <div className="flex flex-wrap justify-between items-center mb-10">
          <span className="text-black text-lg sm:text-[18px] font-medium mb-4 sm:mb-2">
            Mega Sale Upto 50% OFF
          </span>
          <a href="/" className="flex flex-col items-start items-center pb-5">
            <div className="flex items-center">
              <span className="text-red text-md sm:text-[18px] font-medium mr-3">
                View All
              </span>
              <img
                src="CaretCircleRight.png"
                alt="Caret Icon"
                className="w-5 h-5"
              />
            </div>
            <div className="w-full h-[2px] bg-red" />
          </a>
        </div> */}
        {/* <ProductItem /> */}
        <div className="mb-10" />
      </div>
    </div>
  );
};

export default ProductPage;