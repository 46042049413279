import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
// import im1 from '../../../src/assets/images/Bannar Big (2).png';
import im1 from '../../../src/assets/images/valen1.png';
//import im2 from '../../../src/assets/images/Group 1321315141.png';
import im2 from '../../../src/assets/images/valen5.png';
import im3 from '../../../src/assets/images/Group 1.png';
import im4 from '../../../src/assets/images/Frame 1321315104 (2).png';
import slh from '../../../src/assets/images/SantaLeftHand.png';
import srh from '../../../src/assets/images/SantaRightHand.png';
// import ChristmasSanta from "../../../src/assets/images/santa.png";
import ChristmasSanta from "../../../src/assets/images/valen3.png";


const ChristmasBanner2: React.FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <div className="hidden lg:grid grid-cols-1 -mt-8 md:grid-cols-12 gap-4 lg:px-12 md:p-6 ">
      {/* Left Section */}
      <div className="md:col-span-8 flex flex-col justify-center  items-center rounded-lg relative">
        <div className="w-full h-full flex items-center justify-start relative bg-[#740202] rounded-lg">
          <img
            src={im1}
            alt="Festive Box"
            className="w-full h-full object-cover rounded-lg"
          />
          <div className="absolute left-0 right-0 flex justify-center z-10">
            <button
              className="bg-white text-[#b0042c] font-bold py-2 px-12 text-[20px] rounded-md hover:bg-[#b0042c]-hover"
              onClick={() => navigate('/valentine-special')}
            >
              Order Now
            </button>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="md:col-span-4 grid grid-rows-2 gap-2 -ml-1">
        {/* Top Box */}
        <div className="relative rounded-md  overflow-hidden">
          <img
            src={im2}
            alt="Gift Box"
            className="w-full h-full object-cover rounded-md"
          />
           <div className="absolute top-36 right-40 flex justify-center">
  {/* <img
    src={im4}
    alt="Shop Now Button"
    className="cursor-pointer h-[40px] w-[120px] hover:bg-red-200"
    onClick={() => navigate('/shop')} // Navigate to /shop
  /> */}
  {/* <button
    className="bg-green text-white px-12 py-2 rounded hover:bg-green-hover"
    onClick={() => navigate('/shop')}
  >
   Order Now
  </button> */}
</div>

        </div>

        {/* Bottom Box */}
        <div className="relative rounded-md overflow-hidden">
  {/* Santa Image */}
  <img
    src={ChristmasSanta}
    alt="Discount Box"
    className="w-full h-full object-cover rounded-md"
  />
</div>

      </div>
    </div>
  );
};

export default ChristmasBanner2;
