import React, {useState } from "react";
import "../../App.css";
import "../../index.css";
import ProductSlider from "./imageSlider";
import StarFill from "../../assets/images/Star 6.png";
import { useAppDispatch } from "../../hooks";
import {
  addUpdateItemAsync,
  getItemsByIdAsync,
} from "../../services/reducers/reciepeReducer";
import { capitalizeWords } from "../../utils/common";
import BlankStar from "../../assets/images/Star 7.png";
import LoginModal from "../Home/LoginModal";
import { useNavigate } from "react-router-dom";

interface ProductProps {
  productDetail?: any;
}
const ProductCard: React.FC<ProductProps> = ({ productDetail }) => {
  const userId = localStorage.getItem("userId");
  const dispatch = useAppDispatch();

  //const cart = useAppSelector((state) => state.receipe.itemsList);
  //const storedCountry = localStorage.getItem('selectedCountry');
    const storedCode = localStorage.getItem('countryCode');
 const [quantity, setQuantity] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleAdd = (
    productId: number,
    ingredient: any,
    newQuantity: number
  ) => {
    if (!userId) {
      console.error("User ID is not available");
      setIsModalOpen(true);
      return;
    }
    if (userId && storedCode) {
      //const newQuantity = (prev[productId] || 0) + 1;
      const request = {
        // group: `Shop- -1`,
        group: `Shop-0`,
        country: storedCode,
        items: [
          {
            ...ingredient,
            selected: newQuantity,
          },
        ],
      };

      dispatch(addUpdateItemAsync({ id: userId, request })).then(() => {
        dispatch(getItemsByIdAsync({ id: userId, country: storedCode }));
        setQuantity(newQuantity);
        navigate(`/cart`);
      });
    }
  };

  const handleIncrease = () => {
    handleAdd(productDetail.id, productDetail, quantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      handleAdd(productDetail.id, productDetail, quantity - 1);
    } else {
      setQuantity(0);
    }
  };

  return (
    <div className="flex flex-col  lg:flex-row lg:space-x-8 p-5 lg:p-0">
      {/* img box */}
      <div className="w-full lg:w-1/2 mb-2 lg:mb-0 object-cover rounded-lg overflow-hidden">
  <div className="rounded-lg max-w-full lg:max-w-[80%] mx-auto">
    <ProductSlider img={productDetail.image} />
  </div>
</div>



      {/* product details */}
      <div className="details w-full -mt-16 md:-mt-10 lg:mt-7  lg:w-full md:pl-5">
        <span className="font-medium text-left text-[20px] sm:text-[20px] md:text-[22px] lg:text-[24px] block">
          {capitalizeWords(productDetail.name)}
        </span>

        {/* five stars */}
        <div className="flex flex-row space-x-1 mt-2">
          {[...Array(5)].map((_, i) => (
            <img
              key={i}
              src={i < productDetail.rating ? StarFill : BlankStar}
              alt="Star Icon"
              style={{ width: 15, height: 15 }}
            />
          ))}
        </div>

        {/* price */}
        <div className="pt-5 flex items-center">
          {/* <span
            className={`text-[16px] sm:text-[16px] lg:text-[20px] ${productDetail.revised_price > productDetail.price
              ? "text-[#B8B8B8] line-through mr-2 font-medium"
              : "text-black font-bold"
              }`}
          > */}
            <span
            className="text-[16px] sm:text-[16px] lg:text-[20px] text-[#3BB77E] font-bold">
            {storedCode === "IN" ? "₹" : "£"}{productDetail.price}
          </span>
          {/* <span className="text-[20px] sm:text-[20px] lg:text-[25px] font-bold text-black ml-4 lg:ml-10">
            {productDetail.revised_price > productDetail.price
              ? `${storedCode === "IN" ? "₹" : "£"}${productDetail.revised_price}`
              : ""}
          </span> */}
        </div>

        {/* description */}
        <div>
          <p className="text-[14px] sm:text-[16px] lg:text-[16px] text-[#525C60] mt-3 lg:mt-2 mb-4 lg:mb-5">
            {productDetail.description !=="NaN" && productDetail.description}
          </p>
        </div>

        {/* responsive button */}
        {productDetail.status==="0.0" ? <div> Product Out Of Stock </div> : quantity === 0 ? (
          <button
            className="border-2 border-green rounded-md font-medium text-[16px] sm:text-[18px] lg:text-[20px] text-green hover:bg-green hover:text-white px-8 mt-2 sm:px-8 lg:px-14 py-2 sm:py-2.5 lg:py-3 mb-5 lg:mb-5"
            onClick={(e) => {
              e.stopPropagation();
              handleAdd(productDetail.id, productDetail, 1);
            }}
          >
            Add
          </button>
        ) : (
          <div className="border-2 border-green bg-green rounded-md font-medium text-white text-[16px] sm:text-[18px] lg:text-[20px] flex items-center justify-center px-8 mt-2 sm:px-8 lg:px-14 py-2 sm:py-2.5 lg:py-3 mb-5 lg:mb-5 w-1/5">
            <button
              className="text-white"
              onClick={(e) => {
                e.stopPropagation();
                handleDecrease();
              }}
            >
              -
            </button>
            <span className="mx-4">{quantity}</span>
            <button
              className="text-white"
              onClick={(e) => {
                e.stopPropagation();
                handleIncrease();
              }}
            >
              +
            </button>
          </div>
        )}

        {/* <span className="text-[20px] mt-5 sm:text-[20px] lg:text-[18px] font-semibold block">
          Egg Dishes
        </span>
        <span className="text-[14px] sm:text-[14px] lg:text-[16px] text-[#9B9B9B] block">
          Easy Recipes with EGG
        </span> */}

        {/* recipe row with images */}
        {/* <div className="mt-5 flex justify-start space-x-5 overflow-x-auto">
          <a href="/">
            <div className="flex flex-col items-center">
              <img src="Rectangle 1684.png" alt="Shakshuka" />
              <h4 className="text-center mt-2 text-[12px] sm:text-[14px] lg:text-[16px] font-medium">
                Shakshuka
              </h4>
            </div>
          </a>
          <a href="/">
            <div className="flex flex-col items-center">
              <img src="Rectangle 1685.png" alt="Deviled Eggs" />
              <h4 className="text-center mt-2 text-[12px] sm:text-[14px] lg:text-[16px] font-medium">
                Deviled Eggs
              </h4>
            </div>
          </a>
          <a href="/">
            <div className="flex flex-col items-center">
              <img src="Rectangle 1686.png" alt="Egg Muffin" />
              <h4 className="text-center mt-2 text-[12px] sm:text-[14px] lg:text-[16px] font-medium">
                Egg Muffin
              </h4>
            </div>
          </a>
        </div> */}
      </div>
      {isModalOpen && (
        <LoginModal
          onClose={() => {
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
};

export default ProductCard;
