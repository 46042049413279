import React from "react";
import "../../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ProductProps {
  img?: any;
}
const ProductSlider: React.FC<ProductProps> = ({img}) => {

  return (
    <div className="slider-container w-full sm:w-[300px] md:w-[500px] lg:w-[500px] mb-5">
       <div
           // key={index}
            className="flex  w-full sm:w-[360px] md:w-[460px] lg:w-[560px] h-[300px] sm:h-[360px] md:h-[460px] lg:h-[560px] rounded-2xl lg:rounded-3xl relative p-2 sm:p-0"
          >
            {/* Discount badge */}
            {/* <div className="absolute top-0 right-0 bg-[#54B22C] pt-1 pb-1 sm:pt-2 sm:pb-2 w-20 sm:w-15 rounded-tr-2xl lg:rounded-tr-3xl rounded-bl-2xl lg:rounded-bl-3xl flex items-center justify-center">
              <span className="font-bold text-center text-white text-[20px] sm:text-[18px]">
                {product.discount} <br />
                OFF
              </span>
            </div> */}

            {/* Product image */}
            <div className="img flex items-center justify-center w-full h-[250px] sm:h-[250px] md:h-[420px] lg:h-[460px]">
              <img
                src={img}
                alt={img}
                className="object-cover rounded-lg w-full h-full"
              />
            </div>
          </div>
      {/* <Slider {...settings}>
        {products.map((product, index) => (
          <div
            key={index}
            className="flex bg-[#F9F8F8] w-full sm:w-[360px] md:w-[460px] lg:w-[560px] h-[300px] sm:h-[360px] md:h-[460px] lg:h-[560px] rounded-2xl lg:rounded-3xl relative p-2 sm:p-0"
          >
         
            <div className="absolute top-0 right-0 bg-[#54B22C] pt-1 pb-1 sm:pt-2 sm:pb-2 w-20 sm:w-15 rounded-tr-2xl lg:rounded-tr-3xl rounded-bl-2xl lg:rounded-bl-3xl flex items-center justify-center">
              <span className="font-bold text-center text-white text-[20px] sm:text-[18px]">
                {product.discount} <br />
                OFF
              </span>
            </div>

       
            <div className="img flex items-center justify-center w-full h-[250px] sm:h-[250px] md:h-[420px] lg:h-[460px]">
              <img
                src={product.img}
                alt={product.alt}
                className="object-contain w-full h-full"
              />
            </div>
          </div>
        ))}
      </Slider> */}
    </div>
  );
}

export default ProductSlider;